.blog-details-content {
  p {
    @include min-screen(1500) {
      line-height: 2;
    }
  }
}

.blog-details-thumb {
  margin-bottom: 45px;
  img {
    border-radius: 6px;
  }
}

.blog-details-meta {
  font-family: "Metropolis Light";
  color: $sub-headings-color;
  line-height: 1.333;
  margin-bottom: 10px;
  font-size: 18px;
  a {
    color: $sub-headings-color;
    &:hover {
      color: $primary;
    }
  }
  .separator {
    margin: 0 8px;
  }
}
.blog-details-title {
  font-weight: 700;
  color: $dark;
  line-height: 1.142;
  margin-bottom: 40px;
  font-size: 42px;
  @include max-screen(767) {
    font-size: 30px;
  }
}
.blog-details-list {
  P {
    color: $dark;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  I {
    margin-right: 10px;
    display: inline-block;
  }
}

.blog-qutation {
  position: relative;
  background: $lime-600;
  padding: 45px 40px 25px;
  margin: 50px 0px;
  border-bottom: 6px solid $dark;
  P {
    color: $dark;
    line-height: 1.777;
    font-size: 18px;
  }
  I {
    color: $dark;
    position: absolute;
    right: 30px;
    bottom: 20px;
    font-size: 50px;
  }
}

.blog-details-grid {
  .col {
    @include max-screen(991) {
      min-width: 100%;
    }
  }
  img {
    @include min-screen(1500) {
      margin-left: 64px;
    }
    border-radius: 4px;
  }
}

.social-tags {
  padding-top: 30px;
  margin-top: 50px;
  margin-bottom: 70px;
  border-top: 1px solid $primary;
  p {
    color: $dark;
    margin-bottom: 0;
  }
  i {
    margin-right: 10px;
  }
}

.social-links {
  li {
    & + li {
      margin-left: 10px;
    }
    &.share {
      font-size: 18px;
      color: $card-headings-color;
    }
    a {
      color: $dark;
      font-size: 18px;
      &:hover {
        color: $primary;
      }
      i {
        margin: 0;
      }
    }
  }
}

.blog-comments {
  margin-bottom: 55px;
  p {
    color: $dark;
    line-height: 1.75;
  }
}

.blog-comment-title {
  font-weight: 600;
  line-height: 1.9166;
  margin-bottom: 10px;
  color: $dark;
}

.authors {
  margin-bottom: -75px;
}

.author-list {
  margin-bottom: 75px;
  &:nth-child(even) {
    @include min-screen(1500) {
      margin-left: 140px;
    }
    @include max-screen(768, 1499) {
      margin-left: 113px;
    }
  }
}

.author-profile {
  margin-right: 30px;
  border-radius: 50%;
}
.author-info {
  flex: 1;
}
.author-title {
  font-weight: 600;
  color: $dark;
  font-size: 18px;
  margin-bottom: 14px;
}
.author-meta {
  color: $primary;
  line-height: 1;
  font-size: 14px;
}
.replay {
  font-weight: 600;
  font-size: 14px;
  color: $dark;
  line-height: 1 !important;
}

.blog-pt-55 {
  padding-top: 55px;
}
